/* eslint-disable react/jsx-one-expression-per-line */
import React from 'react';
import {
  useHistory,
  useLocation,
} from 'react-router-dom/cjs/react-router-dom.min';
import Button from '~/components/button';
import { icons } from '~/assets';
import * as S from './styles';
import { currencyMask } from '~/utils/masks';
import Container from '~/components/container';

export default function ProposalResume() {
  const { state } = useLocation();
  const history = useHistory();
  const propostaAprovada = state?.status === 'APROVADA';
  const propostaAnalise = state?.status === 'ANALISE';

  console.log(propostaAprovada);

  return (
    <Container>
      <S.Container>
        <h3>
          {propostaAprovada
            ? 'Parabéns! A tua proposta foi aprovada'
            : 'Proposta em análise'}
        </h3>
        <div className="icon">
          <img alt="cardsIcon" src={icons.allCards} />
        </div>
        <div className="container-detail">
          {propostaAnalise && (
            <div className="details" style={{ margin: '0px 20%' }}>
              <h2>
                Aguarde os próximos passos em teu e-mail ou entre em contato
                pelo número 4003-3203
              </h2>
              <h2 style={{ textAlign: 'center' }}>TeuCard agradece!</h2>
            </div>
          )}

          {propostaAprovada && (
            <div className="details">
              <>
                <h2>Confira o resumo da tua proposta:</h2>
                <div>Nome: {state?.nome}</div>
                <div>E-mail: {state?.email}</div>
                <div>Dia do vencimento da fatura: {state?.vencimento}</div>
                <div>
                  Limite de crédito total:{' '}
                  {currencyMask(state?.limiteSaque + state?.limiteCompras)}
                </div>
                <div>
                  Limite de crédito para compras:{' '}
                  {currencyMask(state?.limiteCompras)}
                </div>
                <div>
                  Limite de crédito para empréstimo:{' '}
                  {currencyMask(state?.limiteSaque)}
                </div>
              </>
            </div>
          )}
        </div>
        <div className="button">
          <Button
            style={{ padding: '24px' }}
            size="large"
            fullWidth
            onClick={() => {
              history.push('/credit-table/proposal');
            }}
          >
            OK
          </Button>
        </div>
      </S.Container>
    </Container>
  );
}
