import { FormField } from '~/utils/form';

export const detailHeaderConference = [
  {
    id: 1,
    value: 'Nome Completo',
    maxChar: 50,
    textAlign: 'left',
  },
  {
    id: 2,
    value: 'Parcelas antecipadas',
    maxChar: 50,
    textAlign: 'center',
  },
  {
    id: 3,
    value: 'Data da compra',
    maxChar: 50,
    textAlign: 'center',
  },
  {
    id: 4,
    value: 'Valor antecipado',
    maxChar: 50,
    textAlign: 'center',
  },
];

export interface IAntecipacionForm {
  cpf: FormField;
  numeroCartao: FormField;
}

export interface IlistAntecipacion {
  border?: boolean;
  id?: any;
  title?: string;
  value?: any;
  item?: any;
  valueColor?: string;
  searchAction?: Function;
  maxChar?: number;
  scratched?: boolean;
  reportView?: boolean;
  icon?: string;
  origin?: string;
  fontsize?: string;
  type?: string;
  textAlign?: string;
  marginLeft?: number;
}

export interface IAntecipationPurchase {
  cpf?: string;
  email?: string;
  cartao?: string;
  tipoAntecipacao?: string;
  dataCorte?: string;
  dataFinanciamento?: string;
  dataVencimento?: string;
  dataCompra?: string;
  idParcelamento?: string;
  idFatura?: string;
  idFinanciamento?: string;
  nomeCompleto?: string;
  qtdParcelasAPagar?: number;
  qtdParcelasPagas?: number;
  parcelasAAntecipar?: number;
  status?: string;
  valorFinanciado?: number;
  valorParcela?: number;
  valorPendente?: number;
  valorAntecipacao?: number;
  valorReal?: number;
  valorDesconto?: number;
  valorTotal?: number;
  valorCompra?: number;
  numeroCartao?: string;
  idCompra?: string;
}

export interface ISelectParcels {
  data?: IAntecipationPurchase;
  qtdParcelasAPagar?: number;
  setDataParcelas?: (dataPurchase: IAntecipationPurchase) => void;
}

export interface IHeaderConference {
  cpf?: string;
  nomeCompleto?: string;
  valorTotal?: number;
  numeroCartao?: string;
  data?: IAntecipationPurchase[];
}
