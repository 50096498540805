import React, { useEffect, useState } from 'react';
import { FaDownload } from 'react-icons/fa';
import moment from 'moment';
import ContainerFlex from '~/components/containerFlex';
import Select from '~/components/select';
import { FormField, setFormState } from '~/utils/form';
import Button from '~/components/button';
import Dialog from '~/components/dialog';
import { icons } from '~/assets';
import Endpoints from '~/services/endpoints';
import useApi, { ApiMethod } from '~/hooks/useApi';
import Loading from '~/components/loading';

import * as St from './styles';
import Container from '~/components/container';

interface Form {
  statusCartao: FormField;
}

interface IDownloadFileCharge {
  base64: string;
  filename: string;
}

interface IStatusList {
  status: string;
  url: string;
}

const FileCustomerBase: React.FC = () => {
  const [form, setForm] = useState<Form>({
    statusCartao: { name: 'statusCartao', value: '' },
  });

  const [dialogIsOpen, setDialogIsOpen] = useState(false);
  const [listStatus, setListStatus] = useState<IStatusList[]>();
  const [selectStatus, setSelectStatus] = useState<IStatusList>({
    status: '',
    url: '',
  });
  const [loading, setLoading] = useState(false);

  const getReportFileCustomerBase = useApi<IStatusList>(
    Endpoints.graphc.getDownloadClientBase,
    ApiMethod.GET,
  );

  const STATUS_CARTAO = [
    {
      id: 'ATIVO',
      value: 'Ativo',
    },
    {
      id: 'CANCELADO',
      value: 'Cancelado',
    },
    {
      id: 'INATIVO',
      value: 'Inativo',
    },
    {
      id: 'INADIMPLENTE',
      value: 'Inadimplente',
    },
    {
      id: 'COBRANCA',
      value: 'Cobrança',
    },
  ];

  useEffect(() => {
    getListStatusDownload();
  }, []);

  const getListStatusDownload = async () => {
    setLoading(true);
    try {
      const response = await getReportFileCustomerBase.callApi();

      if (response.status >= 200 && response.status < 300) {
        const data = response.data as IStatusList[];
        setListStatus(data);
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getCustomerBaseReportDownload = (data) => {
    setLoading(true);
    try {
      if (listStatus) {
        const link = document.createElement('a');
        const fileName = `${moment().format('YYYY-MM-DD')}-${
          data?.status
        }-clientes.csv`;

        link.href = data?.url;
        link.setAttribute('download', fileName);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        setLoading(false);
      } else {
        setDialogIsOpen(true);
        setLoading(false);
      }
    } catch (error) {
      setDialogIsOpen(true);
      setLoading(false);
    } finally {
      setDialogIsOpen(false);
      setLoading(false);
    }
  };

  return (
    <>
      <Loading status={loading} />
      <Dialog
        icon={icons.exclamation}
        open={dialogIsOpen}
        positiveLabel="Entendi"
        positiveAction={() => setDialogIsOpen(false)}
        message="Não foi possível baixar o arquivo."
      />

      <Container>
        <St.BoxCard>
          <ContainerFlex row space>
            <ContainerFlex padding={16} flex={0.2}>
              <Select
                label="Status do cartão"
                value={selectStatus.status}
                onChange={(e) => {
                  setSelectStatus((prev) => ({
                    ...prev,
                    url: listStatus?.find((d) => d.status === e).url,
                    status: e,
                  }));
                }}
                options={listStatus?.map((e) => ({
                  id: e.status,
                  value: e.status,
                  url: e.url,
                }))}
              />
            </ContainerFlex>

            <ContainerFlex padding={16}>
              <Button
                fitContent
                disabled={!selectStatus.status}
                onClick={() => {
                  if (selectStatus?.status) {
                    getCustomerBaseReportDownload(selectStatus);
                  }
                }}
              >
                <FaDownload />
                <span style={{ marginLeft: '8px' }}>Download</span>
              </Button>
            </ContainerFlex>
          </ContainerFlex>
        </St.BoxCard>
      </Container>
    </>
  );
};

export default FileCustomerBase;
