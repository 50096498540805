/* eslint-disable react/jsx-no-undef */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { useHistory, useLocation } from 'react-router-dom';

import Spinner from '~/components/spinner';
import ContainerFlex from '~/components/containerFlex';
import Grid from '~/components/grid';
import Input from '~/components/input';
import Button from '~/components/button';
import Loading from '~/components/loading';
import Dialog from '~/components/dialog';

import Endpoints from '~/services/endpoints';
import { FormField, setFormState } from '~/utils/form';
import * as Masks from '~/utils/masks';

import useApi, { ApiMethod } from '~/hooks/useApi';

import { icons } from '~/assets';
import * as St from './styles';
import Container from '~/components/container';
import DigitalWalletResponse from '~/services/transaction-consult/digital-wallet/response';
import Constants from '~/utils/constants';
import PasswordModal from '~/components/passwordModal';

interface LocationState {
  idTransacao?: string;
}

interface Form {
  cIdOperador?: string;
  cNumeroCartao?: FormField;
  cCelular?: FormField;
  cCpf?: FormField;
  cNome?: FormField;

  tIdTransacao?: FormField;
  tLocalPagamento?: FormField;
  tValorParcela?: FormField;
  tTipo?: FormField;
  tDataTransacao?: FormField;
  tHoraTransacao?: FormField;
  tQuantidadeParcelas?: FormField;
  tValorTransacao?: FormField;
  tValorCupom?: FormField;
  idAutorizador?: FormField;
  tNSU?: FormField;
  tNsuRedeCaptura?: FormField;
  tNsuTef?: FormField;
  tTerminal?: FormField;
  tStatus?: FormField;
  tBandeira?: FormField;
  mensagemErro?: FormField;
}

const DigitalWalletDetail = (): JSX.Element => {
  const [dialoagInstallments, setDialoagInstallments] = useState(false);
  const userRoles = JSON.parse(localStorage.getItem(Constants.USER_ROLE));
  const [dialogInformativeIsOpen, setDialogInformativeIsOpen] = useState(false);
  const [modalPasswordOpen, setModalPasswordOpen] = useState<{
    open: boolean;
    type: 'cancelTransaction' | '';
  }>({ open: false, type: '' });
  const [dialogInformativetText, setInformativetText] = useState('');
  const [dialogOpen, setDialogOpen] = useState(false);
  const [form, setForm] = useState<Form>({
    cCelular: { name: 'cCelular', value: '' },
    cCpf: { name: 'cCpf', value: '' },
    cNome: { name: 'cNome', value: '' },
    cNumeroCartao: { name: 'cNumeroCartao', value: '' },
    tDataTransacao: { name: 'tDataTransacao', value: '' },
    tLocalPagamento: { name: 'localPagamento', value: '' },
    tHoraTransacao: { name: 'tHoraTransacao', value: '' },
    tIdTransacao: { name: 'tIdTransacao', value: '' },
    tTipo: { name: 'tTipo', value: '' },
    idAutorizador: { name: 'idAutorizador', value: '' },
    tValorTransacao: { name: 'tValorCompra', value: '' },
    tValorCupom: { name: 'tValorCupom', value: '' },
    tValorParcela: { name: 'tValorParcela', value: '' },
    tQuantidadeParcelas: { name: 'tQuantidadeParcelas', value: '' },
    tNSU: { name: 'tNSU', value: '' },
    tNsuRedeCaptura: { name: 'tNsuRedeCaptura', value: '' },
    tNsuTef: { name: 'tNsuTef', value: '' },
    tTerminal: { name: 'tTerminal', value: '' },
    tStatus: { name: 'tStatus', value: '' },
    mensagemErro: { name: 'mensagemErro', value: '' },
    tBandeira: { name: 'tBandeira', value: '' },
  });

  const location = useLocation<LocationState>();
  const history = useHistory();
  const result = ['ADM', 'SUP'].some((role) => userRoles.includes(role));

  const api = useApi<DigitalWalletResponse>(
    Endpoints.consult.walletPaymentDetail.replace(
      'PARAM_ID',
      location?.state?.idTransacao,
    ),
    ApiMethod.GET,
  );

  const RequestWalletPaymentCancel = useApi(
    Endpoints.consult.walletPaymentCancel.replace('PARAM_NSU', form.tNSU.value),
    ApiMethod.PUT,
  );

  useEffect(() => {
    api.callApi();
  }, []);

  useEffect(() => {
    loadData();
  }, [api.loading]);

  const loadData = () => {
    const data = api.data as DigitalWalletResponse;

    const telefone = Masks.phoneMask(Masks.phoneUnmask(data?.telefone));

    const obj: Form = {
      cCelular: {
        ...form?.cCelular,
        value: telefone.masked,
        unmasked: telefone.unmasked,
      },
      cCpf: {
        ...form?.cCpf,
        value: Masks.cpfMask(data?.cpf).masked,
        unmasked: Masks.cpfMask(data?.cpf).unmasked,
      },
      cNome: { ...form?.cNome, value: data?.nome },
      cNumeroCartao: {
        ...form?.cNumeroCartao,
        value: Masks.creditCardMask(data?.numeroCartao).unmasked,
      },

      tDataTransacao: {
        ...form?.tDataTransacao,
        value: moment(data?.dataHora).format('DD-MM-YYYY'),
      },
      tLocalPagamento: {
        ...form?.tLocalPagamento,
        value: data?.nomeLoja,
      },
      tHoraTransacao: {
        ...form?.tHoraTransacao,
        value: `${moment(data?.dataHora).format('HH:mm:ss')}`,
      },
      tIdTransacao: {
        ...form?.tIdTransacao,
        value: location?.state?.idTransacao,
      },
      tQuantidadeParcelas: {
        ...form?.tQuantidadeParcelas,
        value: data?.quantidadeParcelas,
      },
      tBandeira: { ...form?.tBandeira, value: data?.bandeira },
      tTipo: { ...form?.tTipo, value: data?.tipoTransacao },
      tValorTransacao: { ...form?.tValorTransacao, value: data?.valor },
      tValorCupom: { ...form?.tValorCupom, value: data?.valorCupom },
      tValorParcela: { ...form?.tValorParcela, value: data?.valorParcela },
      tNSU: { ...form?.tNSU, value: data?.nsu },
      tNsuRedeCaptura: { ...form.tNsuRedeCaptura, value: data?.nsuRedeCaptura },
      tNsuTef: { ...form.tNsuTef, value: data?.nsuTef },
      tTerminal: { ...form.tTerminal, value: data?.terminal },
      tStatus: { ...form?.tStatus, value: data?.status },
      mensagemErro: { ...form?.mensagemErro, value: data?.mensagemErro },
    };

    setForm(obj);
  };

  const putWalletCancellationPayment = async () => {
    const payload = {
      nsuRedeCaptura: form.tNsuRedeCaptura.value
        ? form.tNsuRedeCaptura.value
        : '',
      nsuTef: form.tNsuTef.value ? form.tNsuTef.value : '',
      status: 'DESFAZIMENTO',
      terminal: form.tTerminal.value ? form.tTerminal.value : '',
    };

    try {
      const response = await RequestWalletPaymentCancel.callApi(payload);
      if (response.status >= 200 && response.status < 300) {
        setDialogInformativeIsOpen(false);
        setDialogOpen(true);
        setInformativetText('Desfazimento realizado com sucesso!');
        api.callApi();
      } else {
        setDialogInformativeIsOpen(false);
        setDialogOpen(true);
        setInformativetText(
          response.mensagem ? response.mensagem : 'Erro ao realizar a operação',
        );
      }
    } catch (error) {
      console.log(error);
    }
  };

  const onClickOpenInstallmentPouUp = () => {
    setDialoagInstallments(true);
    api.callApi();
  };

  const goBack = () => history.goBack();

  return (
    <>
      <Loading status={api.loading || RequestWalletPaymentCancel.loading} />
      <Dialog
        icon={icons.exclamation}
        open={dialoagInstallments}
        title={<span style={{ color: 'red' }}> Parcelas </span>}
        positiveLabel="Voltar"
        positiveAction={() => setDialoagInstallments(false)}
      >
        {api.loading ? (
          <ContainerFlex center>
            <Spinner />
          </ContainerFlex>
        ) : (
          <St.Table>
            <tr>
              <th>N° Parcela </th>
              <th style={{ borderRight: '1px solid grey' }}>Valor</th>
            </tr>

            <tr>
              <td>{api?.data?.quantidadeParcelas}</td>
              <td style={{ borderRight: '1px solid grey' }}>
                R$
                {Masks.currencyMask(Number(api?.data?.valorParcela))}
              </td>
            </tr>
          </St.Table>
        )}
      </Dialog>
      <Dialog
        open={dialogInformativeIsOpen}
        icon={icons.exclamation}
        negativeLabel="Voltar"
        negativeAction={() => setDialogInformativeIsOpen(false)}
        positiveAction={() => {
          setModalPasswordOpen({ open: true, type: 'cancelTransaction' });
        }}
        message="Você tem certeza que deseja realizar o desfazimento?"
      >
        {modalPasswordOpen.type === 'cancelTransaction' && (
          <PasswordModal
            onClose={() => setModalPasswordOpen({ open: false, type: '' })}
            onSuccessValidation={putWalletCancellationPayment}
          />
        )}
      </Dialog>
      <Dialog
        open={dialogOpen}
        icon={icons.check}
        positiveLabel="Entendi"
        positiveAction={() => {
          setDialogOpen(false);
        }}
        message={dialogInformativetText}
      />
      <Container>
        <St.BoxCard>
          <ContainerFlex color="#fbfbfb">
            <St.TitleCategory>1. Cliente</St.TitleCategory>
          </ContainerFlex>

          <ContainerFlex padding={26}>
            <Grid xs={1} lg={2} gap={30}>
              <ContainerFlex>
                <Input
                  label="Número do cartão"
                  value={form?.cNumeroCartao.value}
                  name={form?.cNumeroCartao.name}
                  onChange={(e) => setFormState(e, form, setForm)}
                  readOnly
                />
                <Input
                  label="Nome completo"
                  value={form?.cNome.value}
                  name={form?.cNome.name}
                  onChange={(e) => setFormState(e, form, setForm)}
                  readOnly
                />
                <Input
                  label="CPF"
                  value={Masks.cpfMask(form?.cCpf?.value).masked}
                  name={form?.cCpf.name}
                  onChange={(e) => setFormState(e, form, setForm)}
                  readOnly
                />
              </ContainerFlex>

              <ContainerFlex>
                <Input
                  label="Telefone"
                  value={form?.cCelular.value}
                  name={form?.cCelular.name}
                  onChange={(e) => setFormState(e, form, setForm)}
                  readOnly
                />
              </ContainerFlex>
            </Grid>
          </ContainerFlex>

          <ContainerFlex row center color="#fbfbfb">
            <St.TitleCategory>2. Transação</St.TitleCategory>
          </ContainerFlex>

          <ContainerFlex padding={26}>
            <Grid xs={1} lg={2} gap={30}>
              <ContainerFlex>
                <Input
                  label="ID da transação"
                  value={form?.tIdTransacao.value}
                  name={form?.tIdTransacao.name}
                  onChange={(e) => setFormState(e, form, setForm)}
                  readOnly
                />
                <Input
                  label="NSU"
                  value={form?.tNSU.value}
                  name={form?.tNSU.name}
                  onChange={(e) => setFormState(e, form, setForm)}
                  readOnly
                />
                <Input
                  label="Valor da Parcela"
                  value={`R$ ${Masks.currencyMask(form?.tValorParcela.value)}`}
                  name={form?.tValorTransacao.name}
                  onChange={(e) => setFormState(e, form, setForm)}
                  readOnly
                />

                <div>
                  <ContainerFlex row>
                    <ContainerFlex marginRight={16}>
                      <Input
                        label="Data da transação"
                        value={form?.tDataTransacao.value}
                        name={form?.tDataTransacao.name}
                        onChange={(e) => setFormState(e, form, setForm)}
                        readOnly
                      />
                    </ContainerFlex>

                    <ContainerFlex>
                      <Input
                        label="Horário da transação"
                        value={form?.tHoraTransacao.value}
                        name={form?.tHoraTransacao.name}
                        onChange={(e) => setFormState(e, form, setForm)}
                        readOnly
                      />
                    </ContainerFlex>
                  </ContainerFlex>
                  <ContainerFlex>
                    <Input
                      label="Valor da transação"
                      value={`R$ ${Masks.currencyMask(
                        Number(form?.tValorTransacao.value),
                      )}`}
                      name={form?.tValorTransacao.name}
                      onChange={(e) => setFormState(e, form, setForm)}
                      readOnly
                    />
                  </ContainerFlex>
                </div>
              </ContainerFlex>

              <ContainerFlex>
                <Input
                  label="Local da transação"
                  value={form?.tLocalPagamento.value}
                  name={form?.tLocalPagamento.name}
                  onChange={(e) => setFormState(e, form, setForm)}
                  readOnly
                />
                <ContainerFlex row>
                  <ContainerFlex marginRight={16}>
                    <Input
                      label="Tipo de transação"
                      value={form?.tTipo.value}
                      name={form?.tTipo.name}
                      onChange={(e) => setFormState(e, form, setForm)}
                      readOnly
                    />
                  </ContainerFlex>
                  <ContainerFlex>
                    <Input
                      label="Bandeira do cartão"
                      value={form?.tBandeira.value}
                      name={form?.tBandeira.name}
                      onChange={(e) => setFormState(e, form, setForm)}
                      readOnly
                    />
                  </ContainerFlex>
                </ContainerFlex>
                <Input
                  label="Quantidade de parcelas"
                  value={form?.tQuantidadeParcelas.value}
                  name={form?.tQuantidadeParcelas.name}
                  onChange={(e) => setFormState(e, form, setForm)}
                  readOnly
                />
                <Input
                  label="Valor do cupom"
                  value={`R$ ${Masks.currencyMask(
                    Number(form?.tValorCupom.value),
                  )}`}
                  name={form?.tValorCupom.name}
                  onChange={(e) => setFormState(e, form, setForm)}
                  readOnly
                />
                <Input
                  label="Status"
                  value={
                    form?.tStatus.value === 'ERRO'
                      ? `${form?.tStatus.value} - ${form?.mensagemErro?.value}`
                      : form?.tStatus.value
                  }
                  name={form?.tStatus.name}
                  onChange={(e) => setFormState(e, form, setForm)}
                  readOnly
                />
              </ContainerFlex>
            </Grid>
          </ContainerFlex>
          <ContainerFlex row end padding={24}>
            {form.tStatus.value === 'PROCESSANDO' && (
              <Button
                disabled={!result}
                onClick={() => setDialogInformativeIsOpen(true)}
              >
                Desfazimento
              </Button>
            )}

            <Button
              fitContent
              marginLeft={15}
              onClick={onClickOpenInstallmentPouUp}
            >
              Ver parcelas
            </Button>
            <Button fitContent marginLeft={15} onClick={goBack} outlined>
              Voltar
            </Button>
          </ContainerFlex>
        </St.BoxCard>
      </Container>
    </>
  );
};

export default DigitalWalletDetail;
