/* eslint-disable react/jsx-curly-newline */
/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable react/jsx-wrap-multilines */
/* eslint-disable react/jsx-indent */
/* eslint-disable no-nested-ternary */
/* eslint-disable array-callback-return */
import React, { useEffect, useState, useMemo, useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import moment from 'moment';

import { useAuth } from '~/hooks/contexts/useAuth';

import IResponseDefaultError from '~/services/default-error/response';
import { IResponseGetAvailablesBenefits } from '~/services/sales/benefits/availables/response';
import {
  IResponseContractedsBenefits,
  IBenefitsDetails,
} from '~/services/sales/benefits/contracteds/response';
import { icons } from '~/assets';
import ContainerFlex from '~/components/containerFlex';
import Dialog from '~/components/dialog';
import Loading from '~/components/loading';
import PictureCard from '~/components/pictureCard';
import Dependent from '../components/dependent';
import Select from '~/components/select';

import TitleApp from '~/components/title';
import useApi, { ApiMethod } from '~/hooks/useApi';
import ConsultUserDetailResponse from '~/services/consult/user/detail/response';
import Endpoints from '~/services/endpoints';
import {
  cpfMask,
  creditCardMask,
  currencyMask,
  phoneMask,
} from '~/utils/masks';

import * as St from './styles';
import Cartao from '~/models/cartao';
import Container from '~/components/container';
import ativo from '~/assets/svg/ativo.svg';
import pendente from '~/assets/svg/pendente.svg';
import suspenso from '~/assets/svg/suspenso.svg';
import cancelado from '~/assets/svg/cancelado.svg';
import permitido from '~/assets/svg/nova-adesao.svg';
import naoPermitido from '~/assets/svg/sem-adesao.svg';
import Constants from '~/utils/constants';

type IBenefit = {
  id?: string;
  idBeneficioAutorizador?: number;
  beneficioContratoPadrao?: string;
  dataCadastro?: string;
  dataCancelamento?: string;
  dataAdesao?: string;
  descricao?: string;
  nome?: string;
  numeroCartao?: string;
  numeroCarteirinha?: string;
  statusBeneficio?: string;
  assinada?: string | boolean;
  valorAdesao?: string | number;
  valorMensalidade?: string | number;
  link?: string;
};
const SalesDetail: React.FC = () => {
  const [benefitSelected, setBenefitSelected] = useState<Partial<IBenefit>>();
  const [availableBenefits, setAvailableBenefits] = useState<
    IResponseGetAvailablesBenefits[]
  >([]);
  const [
    contractedBenefits,
    setContractedBenefits,
  ] = useState<IResponseContractedsBenefits>();
  const [dialogJustificationIsOpen, setDialogJustificationIsOpen] = useState(
    false,
  );
  const [
    dialogJustificationIsDelete,
    setDialogJustificationIsDelete,
  ] = useState(false);
  const [dialogIsOpenDependent, setDialogIsOpenDependent] = useState(false);
  const [dialogIsOpen, setDialogIsOpen] = useState(false);
  const [popUpMessage, setPopUpMessage] = useState('');
  const [notAllowedDialog, setNotAllowedDialog] = useState(false);
  const [dialogIsOpenDetails, setDialogIsOpenDetails] = useState(false);
  const [benefitDetails, setBenefitDetails] = useState<IBenefitsDetails>();
  const [deleteDependentMessage, setDeleteDependentMessage] = useState('');
  const [dialogDeleteDependent, setDialogDeleteDependent] = useState(false);
  const [cancelReason, setCancelReason] = useState('');

  const [dialogIsOpenCancel, setDialogIsOpenCancel] = useState(false);
  const [dialogIsOpenCancelCuida, setDialogIsOpenCancelCuida] = useState(false);
  const [benefitDetailsDialogOpen, setBenefitDetailsDialogOpen] = useState(
    false,
  );
  const [benefitDetailsDialog, setBenefitDetailsDialog] = useState({
    title: null,
    description: null,
    link: null,
  });

  const { state } = useLocation<ConsultUserDetailResponse>();
  const { user } = useAuth();
  const dataAtual = new Date();
  dataAtual.setHours(0, 0, 0);

  const idOperadorDependente = localStorage.getItem('USER_ID');

  const RequestUserDocuments = useApi(
    `${Endpoints.consult.searchUserDocuments}${state?.propostaId}`,
    ApiMethod.GET,
  );

  const RequestAvailablesBenefits = useApi<IResponseGetAvailablesBenefits[]>(
    `${Endpoints.sales.getBenefits.replace('PARAM_CPF', state?.cpf)}`,
    ApiMethod.GET,
  );

  const RequestActiveCard = useApi<Cartao>(
    `${Endpoints.consult.searchClientCpf.replace('PARAM_CPF', state?.cpf)}`,
    ApiMethod.GET,
  );

  const RequestSubscribeBenefits = useApi<IResponseDefaultError>(
    `${Endpoints.sales.subscribeBenefits.replace(
      'PARAM_BACKOFFICE',
      String(state.backOffice !== true),
    )}`,
    ApiMethod.POST,
  );
  const RequestDependentsBenefits = useApi(
    `${Endpoints.sales.postDependent
      .replace('PARAM_ID_PROPOSTA', state.propostaId)
      .replace('PARAM_BACKOFFICE', String(state.backOffice !== true))}`,
    ApiMethod.POST,
  );

  const RequestContractedBenefits = useApi<
    IResponseContractedsBenefits & IResponseDefaultError
  >(
    `${Endpoints.sales.getContractedBenefits.replace(
      'PARAM_ID_PROPOSTA',
      state?.propostaId,
    )}`,
    ApiMethod.GET,
  );

  const RequestDeleteBenefit = useApi(
    `${Endpoints.sales.deleteBenefit
      .replace('PARAM_ID_PROPOSTA', state?.propostaId)
      .replace('PARAM_ID_BENEFICIO', benefitSelected?.id)}`,
    ApiMethod.DELETE,
  );
  const RequestDeleteBenefitDependent = useApi(
    `${Endpoints.sales.deleteBenefitDependent}`,
    ApiMethod.DELETE,
  );
  const RequestBenefitDetails = useApi<IBenefitsDetails>(
    `${Endpoints.sales.getBenefitDetails.replace(
      'PARAM_ID_PROPOSTA',
      state?.propostaId,
    )}`,
    ApiMethod.GET,
  );

  const isUserMoreThan65YearsOld =
    new Date().getFullYear() -
      new Date(RequestActiveCard?.data?.dataNascimento).getFullYear() >
    65;

  const getUserData = useCallback(() => {
    RequestAvailablesBenefits.callApi().then((responseGetBenefits) => {
      if (responseGetBenefits.status === 200) {
        setAvailableBenefits(responseGetBenefits.data);
      }
    });

    RequestBenefitDetails.callApi().then((response) => {
      if (response.status === 200) {
        setBenefitDetails(response.data as IBenefitsDetails);
      }
    });

    RequestUserDocuments.callApi();

    RequestActiveCard.callApi();

    RequestContractedBenefits.callApi().then((response) => {
      if (response.status === 200) {
        setContractedBenefits(response.data);
      }
    });
  }, []);
  useEffect(() => {
    if (state) {
      getUserData();
    }
  }, [state]);
  const handleDeleteBenefit = async (id) => {
    try {
      RequestDeleteBenefit.callApi().then((response) => {
        if (response.status >= 200 && response.status < 300) {
          setContractedBenefits(id);
          setDialogJustificationIsDelete(false);
          getUserData();
        }
        if (response.status === 404) {
          const { mensagem }: any = response.data;
          setDialogJustificationIsDelete(false);
          getUserData();
          setPopUpMessage(
            mensagem ||
              'Erro ao deletar benefício. Tente novamente mais tarde.',
          );
          setDialogIsOpen(true);
        }
      });
    } catch (error) {
      console.log(error);
      console.log(error.message);
    }
  };

  const confirmMembership = () => {
    if (
      isUserMoreThan65YearsOld &&
      ![1, 5].includes(benefitSelected?.idBeneficioAutorizador)
    ) {
      setPopUpMessage(
        'Ops.... Adesão negada! Não é permitido para maiores de 65 anos.',
      );
      setDialogJustificationIsOpen(false);
      setDialogIsOpen(true);
      return;
    }
    const payload = [
      {
        idProposta: state?.propostaId,
        idOperador: user?.id,
        cpf: state?.cpf,
        beneficios: [
          {
            idBeneficio: benefitSelected?.id,
            statusBeneficio: 'ATIVO',
            assinada: true,
          },
        ],
      },
    ];

    RequestSubscribeBenefits.callApi(payload)
      .then((responseSubscribe) => {
        if (responseSubscribe.status === 200) {
          setPopUpMessage('Adesão ao benefício realizado com sucesso!');
        } else {
          setPopUpMessage(
            responseSubscribe.data.mensagem || 'Falha ao aderir ao benefício!',
          );
        }

        RequestContractedBenefits.callApi().then((response) => {
          if (response.status === 200) {
            setContractedBenefits(response.data);
          }
        });
      })
      .finally(() => {
        setDialogJustificationIsOpen(false);
        setDialogIsOpen(true);
      });
  };

  const availableBenefitsNotContracteds = useMemo(() => {
    const newAvailablesBenefits = availableBenefits.filter((available) => {
      return available.disponivelContratacao;
    });

    return newAvailablesBenefits;
  }, [availableBenefits, contractedBenefits]);

  async function addDependent(listDependents) {
    setDialogIsOpenDependent(false);

    const payload: any = [];
    listDependents.map((dependents) => {
      payload.push({
        nome: dependents.nome,
        dataNascimento: moment(dependents.dataNascimento, 'DD/MM/YYYY').format(
          'YYYY-MM-DD',
        ),
        cpf: dependents?.cpf,
        telefone: dependents?.telefone,
        idOperador: idOperadorDependente,
      });
    });

    try {
      RequestDependentsBenefits.callApi(payload).then((response) => {
        if (response.status === 200 || response.status === 204) {
          setPopUpMessage('Dependente(s) adicionado(s) com sucesso!');
        } else {
          setPopUpMessage(
            response.mensagem || 'Não foi possível adicionar dependente(s)!',
          );
        }
      });
    } catch (error) {
      setPopUpMessage('Não foi possível adicionar dependente(s)!');
    } finally {
      setDialogIsOpen(true);
    }
  }
  const activeCardState = RequestActiveCard?.data?.cartaoAtual?.status;

  const isActiveCardNotAllowed = () => {
    if (!(activeCardState === 'ATIVO')) {
      return true;
    }
    return false;
  };
  const handleDeleteDependent = (dependent) => {
    try {
      RequestDeleteBenefitDependent.callApi({
        numeroDocumento: dependent.numeroDocumento || '',
      }).then((response) => {
        if (response.status === 200 || response.status === 204) {
          setDeleteDependentMessage('Dependente excluído com sucesso!');
          setDialogDeleteDependent(true);
        } else {
          setDeleteDependentMessage(
            response.mensagem ||
              'Não foi possível excluir dependente, tente novamente mais tarde!',
          );
          setDialogDeleteDependent(true);
        }
      });
    } catch {
      setDeleteDependentMessage(
        'Não foi possível excluir dependente, tente novamente mais tarde!',
      );
      setDialogDeleteDependent(true);
    }
  };

  const objectReasons = {
    DIFICULADADE_FINANCEIRA: 'Dificuldade financeira',
    DESEJA_CANCELAR_CARTAO: 'Deseja cancelar o cartão',
    CONTRATACAO_SEM_AUTORIZACAO: 'Contratação sem autorização',
    ERRO_LOJA_CONTRATACAO_INDEVIDA: 'Erro loja - Contratação indevida',
    OUTROS_MOTIVOS: 'Outros motivos',
  };
  const handleCheckBenefitLink = (link: string) => {
    const a = document.createElement('a');
    a.href = link;
    a.target = '_blank';
    document.body.appendChild(a);
    a.click();
    a.parentNode.removeChild(a);
  };
  const DeleteCancelBenefit = useApi(
    Endpoints.sales.cancelBenefit,
    ApiMethod.DELETE,
  );

  const DeleteCancelBenefitCuida = useApi(
    Endpoints.sales.cancelBenefitCuida,
    ApiMethod.DELETE,
  );
  const handleCancelBenefit = async (payload) => {
    setDialogIsOpenCancel(false);
    const { status, mensagem } = await DeleteCancelBenefit.callApi(payload);
    if (status >= 200 && status <= 300) {
      setPopUpMessage('Cancelamento realizado com sucesso!');
      setDialogIsOpen(true);
      setDialogIsOpenCancel(false);
      setCancelReason('');
    } else {
      setPopUpMessage(mensagem);
      setDialogIsOpen(true);
      setCancelReason('');
      setDialogIsOpenCancel(false);
    }
  };

  const handleCancelBenefitCuida = async (payload) => {
    setDialogIsOpenCancelCuida(false);
    const { status, mensagem } = await DeleteCancelBenefitCuida.callApi(
      payload,
    );

    if (status >= 200 && status <= 300) {
      setPopUpMessage('Cancelamento realizado com sucesso!');
      setDialogIsOpen(true);
      setDialogIsOpenCancelCuida(false);
      setCancelReason('');
    } else {
      setPopUpMessage(mensagem);
      setDialogIsOpen(true);
      setCancelReason('');
      setDialogIsOpenCancelCuida(false);
    }
  };
  return (
    <Container>
      <Loading
        status={
          RequestUserDocuments.loading ||
          RequestAvailablesBenefits.loading ||
          RequestContractedBenefits.loading ||
          RequestSubscribeBenefits.loading ||
          RequestDependentsBenefits.loading ||
          RequestActiveCard.loading ||
          RequestDeleteBenefit.loading ||
          DeleteCancelBenefit.loading
        }
      />

      <Dialog
        icon={
          RequestSubscribeBenefits.status === 200
            ? icons.confirmation
            : icons.warning
        }
        open={dialogIsOpen}
        positiveLabel="Entendi"
        positiveAction={() => {
          getUserData();
          setDialogIsOpen(false);
        }}
        message={popUpMessage}
      />

      <Dialog
        icon={icons.exclamation}
        open={dialogJustificationIsOpen}
        negativeLabel="Voltar"
        negativeAction={() => setDialogJustificationIsOpen(false)}
        positiveLabel="Solicitar"
        positiveAction={confirmMembership}
        title={<span style={{ color: 'red' }}>Solicitação adesão</span>}
      >
        <span style={{ fontSize: '1em', fontWeight: 600, textAlign: 'center' }}>
          Deseja solicitar a adesão?
        </span>
      </Dialog>

      <Dialog
        icon={icons.exclamation}
        open={dialogJustificationIsDelete}
        negativeLabel="Voltar"
        negativeAction={() => setDialogJustificationIsDelete(false)}
        positiveLabel="Excluir"
        positiveAction={() => handleDeleteBenefit(benefitSelected?.id)}
        title={<span style={{ color: 'red' }}>Exclusão da adesão</span>}
      >
        <span style={{ fontSize: '1em', fontWeight: 600, textAlign: 'center' }}>
          Deseja excluir a adesão?
        </span>
      </Dialog>

      <Dialog
        widthInPercent={30}
        icon={icons.warning}
        open={benefitDetailsDialogOpen}
        negativeLabel="Voltar"
        negativeAction={() => setBenefitDetailsDialogOpen(false)}
        positiveLabel="Mais Detalhes"
        positiveAction={() => handleCheckBenefitLink(benefitDetailsDialog.link)}
        title={
          <span style={{ color: 'red' }}>{benefitDetailsDialog.title}</span>
        }
      >
        <span style={{ fontSize: '1em', fontWeight: 600, textAlign: 'center' }}>
          {benefitDetailsDialog.description}
        </span>
      </Dialog>
      <Dialog
        widthInPercent={30}
        icon={icons.cancel}
        open={dialogIsOpenCancelCuida}
        buttonDisabledPositive={!cancelReason}
        negativeLabel="Voltar"
        negativeAction={() => {
          setCancelReason('');
          setDialogIsOpenCancelCuida(false);
        }}
        positiveLabel="Continuar"
        positiveAction={() =>
          handleCancelBenefitCuida({
            idProposta: state?.propostaId,
            idBeneficio: benefitSelected?.id,
            idUsuario: localStorage.getItem(Constants.USER_ID),
            motivoCancelamento: cancelReason,
          })
        }
        title={<span style={{ color: 'red' }}>Cancelar benefício</span>}
      >
        <>
          <Select
            label="Escolha um motivo de cancelamento"
            value={cancelReason}
            onChange={(val) => {
              console.log(val);
              setCancelReason(val);
            }}
            options={[
              {
                id: 'DIFICULADADE_FINANCEIRA',
                value: 'Dificuldade financeira',
              },
              {
                id: 'DESEJA_CANCELAR_CARTAO',
                value: 'Deseja cancelar o cartão',
              },
              {
                id: 'CONTRATACAO_SEM_AUTORIZACAO',
                value: 'Contratação sem autorização',
              },
              {
                id: 'ERRO_LOJA_CONTRATACAO_INDEVIDA',
                value: 'Erro loja - Contratação indevida',
              },
              { id: 'OUTROS_MOTIVOS', value: 'Outros motivos' },
            ]}
          />
          {cancelReason && (
            <span style={{ fontSize: '1rem' }}>
              Deseja cancelar o benefício <b>{benefitSelected?.nome}</b> pelo
              motivo <b>{objectReasons[cancelReason]}</b> ?
            </span>
          )}
        </>
      </Dialog>
      <Dialog
        widthInPercent={30}
        icon={icons.cancel}
        open={dialogIsOpenCancel}
        buttonDisabledPositive={!cancelReason}
        negativeLabel="Voltar"
        negativeAction={() => {
          setCancelReason('');
          setDialogIsOpenCancel(false);
        }}
        positiveLabel="Continuar"
        positiveAction={() => {
          console.log('Teste --- ', state, benefitSelected, cancelReason);
          handleCancelBenefit({
            idProposta: state?.propostaId,
            idBeneficio: benefitSelected?.id,
            idUsuario: localStorage.getItem(Constants.USER_ID),
            motivoCancelamento: cancelReason,
          });
        }}
        title={<span style={{ color: 'red' }}>Cancelar benefício</span>}
      >
        <>
          <Select
            label="Escolha um motivo de cancelamento"
            value={cancelReason}
            onChange={(val) => {
              console.log(val);
              setCancelReason(val);
            }}
            options={[
              {
                id: 'DIFICULADADE_FINANCEIRA',
                value: 'Dificuldade financeira',
              },
              {
                id: 'DESEJA_CANCELAR_CARTAO',
                value: 'Deseja cancelar o cartão',
              },
              {
                id: 'CONTRATACAO_SEM_AUTORIZACAO',
                value: 'Contratação sem autorização',
              },
              {
                id: 'ERRO_LOJA_CONTRATACAO_INDEVIDA',
                value: 'Erro loja - Contratação indevida',
              },
              { id: 'OUTROS_MOTIVOS', value: 'Outros motivos' },
            ]}
          />
          {cancelReason && (
            <span style={{ fontSize: '1rem' }}>
              Deseja cancelar o seguro <b>{benefitSelected?.nome}</b> pelo
              motivo <b>{objectReasons[cancelReason]}</b> ?
            </span>
          )}
        </>
      </Dialog>
      <Dialog
        icon={icons.warning}
        open={notAllowedDialog}
        negativeLabel="Voltar"
        negativeAction={() => setNotAllowedDialog(false)}
        title={<span style={{ color: 'red' }}>Solicitação adesão</span>}
      >
        <span style={{ fontSize: '1em', fontWeight: 600, textAlign: 'center' }}>
          Apenas cartões com status ATIVO podem solicitar adesão.
        </span>
      </Dialog>

      {dialogIsOpenDependent && (
        <Dependent
          isOpenDependent={dialogIsOpenDependent}
          setIsOpenDependent={(bool) => setDialogIsOpenDependent(bool)}
          setDependent={(listDependents) => addDependent(listDependents)}
        />
      )}

      <St.BoxCard>
        <TitleApp>Detalhes de Benefícios</TitleApp>
        <ContainerFlex row marginTop={26}>
          <PictureCard
            width="150"
            height="150"
            showOptions={!!RequestUserDocuments.data?.fotoCertiface}
            src={
              RequestUserDocuments.data?.fotoCertiface
                ? `data:image/png;base64, ${RequestUserDocuments.data?.fotoCertiface}`
                : icons.userAvatar
            }
          />

          <ContainerFlex marginLeft={20}>
            <span>
              <St.GreyText>CPF:</St.GreyText>
              &nbsp;
              {cpfMask(state?.cpf).masked}
            </span>
            <span>
              <St.GreyText>Nome:</St.GreyText>
              &nbsp;
              {state?.nome}
            </span>
            <span>
              <St.GreyText>Telefone:</St.GreyText>
              &nbsp;
              {phoneMask(state?.celular).masked}
            </span>
            <span>
              <St.GreyText>E-mail:</St.GreyText>
              &nbsp;
              {state?.email}
            </span>
            <span>
              <St.GreyText>Número do Cartão:</St.GreyText>
              &nbsp;
              {state?.cartoes &&
                creditCardMask(
                  state?.cartoes[state.cartoes.length - 1].numeroCartao,
                ).masked}
            </span>
            <span>
              <St.GreyText>Status do cartão:</St.GreyText>
              &nbsp;
              {activeCardState}
            </span>
          </ContainerFlex>
        </ContainerFlex>
        <ContainerFlex>
          <St.UploadImage>
            Clique no ícone para selecionar a imagem
          </St.UploadImage>
        </ContainerFlex>

        <ContainerFlex row marginTop={16} marginBottom={16}>
          <h4>Legenda de Benefícios:</h4>
        </ContainerFlex>

        <ContainerFlex row marginBottom={16}>
          <ContainerFlex row>
            <ContainerFlex row center marginLeft={5}>
              <img src={ativo} height="24" alt="Benefício ativo" />
              <St.LegendLabel>Ativo</St.LegendLabel>
            </ContainerFlex>
            <ContainerFlex row center marginLeft={5}>
              <img src={pendente} height="24" alt="Benefício pendente" />
              <St.LegendLabel>Pendente</St.LegendLabel>
            </ContainerFlex>
            <ContainerFlex row center marginLeft={5}>
              <img src={suspenso} height="24" alt="Benefício suspenso" />
              <St.LegendLabel>Suspenso</St.LegendLabel>
            </ContainerFlex>
            <ContainerFlex row center marginLeft={5}>
              <img src={cancelado} height="24" alt="Benefício cancelado" />
              <St.LegendLabel>Cancelado</St.LegendLabel>
            </ContainerFlex>
            <ContainerFlex row center marginLeft={5}>
              <img src={permitido} height="24" alt="Benefício permitido" />
              <St.LegendLabel>Permitido Adicionar</St.LegendLabel>
            </ContainerFlex>
            <ContainerFlex row center marginLeft={5}>
              <img
                src={naoPermitido}
                height="24"
                alt="Benefício não permitido"
              />
              <St.LegendLabel>Não permitido adicionar</St.LegendLabel>
            </ContainerFlex>
          </ContainerFlex>
        </ContainerFlex>

        <St.Divider />

        <ContainerFlex marginTop={24}>
          {contractedBenefits?.beneficios?.map((benefit, id, arr) => {
            const prevIsSameStatus = arr[id - 1];
            const prevIsSameStatus2 = arr[id + arr.length - 1];

            if (
              prevIsSameStatus?.statusBeneficio === benefit.statusBeneficio ||
              prevIsSameStatus2?.statusBeneficio === benefit.statusBeneficio
            ) {
              return null;
            }
            return (
              <>
                {benefit.assinada && benefit.statusBeneficio === 'ATIVO' ? (
                  <ContainerFlex row align="center">
                    <img
                      width="35"
                      src={icons.emojiAtivo}
                      alt="emojiGreen"
                      style={{ marginRight: '20px' }}
                    />
                    <span>Benefício Ativo</span>
                  </ContainerFlex>
                ) : benefit.statusBeneficio === 'SUSPENSO' ? (
                  <ContainerFlex row align="center">
                    <img
                      width="35"
                      src={icons.emojiSuspenso}
                      alt="emojiOrange"
                      style={{ marginRight: '20px' }}
                    />
                    <span>Benefício Suspenso</span>
                  </ContainerFlex>
                ) : benefit.statusBeneficio === 'PENDENTE' ? (
                  <ContainerFlex row align="center">
                    <img
                      width="35"
                      src={icons.emojiPendente}
                      alt="emojiYellow"
                      style={{ marginRight: '20px' }}
                    />
                    <span>Benefício Pendente</span>
                  </ContainerFlex>
                ) : benefit.statusBeneficio === 'CANCELADO' ||
                  benefit.statusBeneficio === 'CANCELAMENTO_EXPERIENCIA' ||
                  benefit.statusBeneficio === 'CANCELAMENTO_SOLICITACAO' ? (
                  <ContainerFlex row align="center">
                    <img
                      width="35"
                      src={icons.emojiCancelado}
                      alt="emojiRed"
                      style={{ marginRight: '20px' }}
                    />
                    <span>Benefício Cancelado</span>
                  </ContainerFlex>
                ) : (
                  <ContainerFlex row align="center">
                    <img
                      width="35"
                      src={icons.emojiSemAdesao}
                      alt="emojiBlue"
                      style={{ marginRight: '20px' }}
                    />
                    <St.GreyText>Não é permitido a adesão</St.GreyText>
                  </ContainerFlex>
                )}
              </>
            );
          })}
        </ContainerFlex>
        <ContainerFlex marginTop={24}>
          <St.TableTitle>Benefícios Disponíveis</St.TableTitle>
          <St.Table>
            <tr>
              <th>Nome</th>
              <th>Descrição</th>
              <th>Parcela (R$)</th>
              <th>Valor adesão</th>
              <th>Ação</th>
            </tr>
            {availableBenefitsNotContracteds.map((benefit) => (
              <tr key={benefit.id}>
                <td>{benefit.nome}</td>
                <td>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      gap: '5px',
                    }}
                  >
                    <span
                      style={{
                        maxWidth: '46ch',
                        height: '1.5rem',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                      }}
                    >
                      {benefit.descricao.replace(',', '')}
                    </span>

                    <img
                      width={20}
                      height={20}
                      src={icons.exclamation}
                      alt="informações"
                      onClick={(e) => {
                        setBenefitDetailsDialog({
                          title: benefit.nome,
                          description: benefit.descricao,
                          link: benefit.link,
                        });
                        setBenefitDetailsDialogOpen(true);
                      }}
                    />
                  </div>
                </td>
                <td>{currencyMask(benefit.valorMensalidade)}</td>
                <td>{currencyMask(benefit.valorAdesao)}</td>
                <td className="icon-table">
                  <img
                    width="35"
                    src={
                      isActiveCardNotAllowed()
                        ? icons.emojiSemAdesao
                        : icons.emojiNovaAdesao
                    }
                    alt="handshake"
                    style={{
                      cursor: isActiveCardNotAllowed()
                        ? 'not-allowed'
                        : 'pointer',
                    }}
                    onClick={() => {
                      if (isActiveCardNotAllowed())
                        return setNotAllowedDialog(true);

                      setBenefitSelected(benefit);
                      setDialogJustificationIsOpen(true);
                    }}
                  />
                </td>
              </tr>
            ))}
          </St.Table>
        </ContainerFlex>

        <ContainerFlex marginTop={24}>
          <St.TableTitle>Benefícios Contratados</St.TableTitle>
          <St.Table>
            <tr>
              <th>Nome</th>
              <th>Descrição</th>
              <th>Parcela (R$)</th>
              <th>Detalhes</th>
              <th>Data Adesão</th>
              <th>Dependente</th>
              <th>Status</th>
              <th>Cancelar</th>
            </tr>
            {contractedBenefits?.beneficios
              ?.filter((el) => el.statusBeneficio !== 'CANCELADO')
              ?.map((benefit, idx) => {
                const isTeuCardOdonto = benefit.idBeneficioAutorizador === 1;
                const isTeuCardCuida = benefit.idBeneficioAutorizador === 5;

                return (
                  <tr key={benefit.nome + idx.toString()}>
                    <td>{benefit.nome}</td>
                    <td>
                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          gap: '5px',
                        }}
                      >
                        <span
                          style={{
                            maxWidth: '46ch',
                            height: '1.5rem',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                          }}
                        >
                          {benefit.descricao.replace(',', '')}
                        </span>

                        <img
                          width={20}
                          height={20}
                          src={icons.exclamation}
                          alt="informações"
                          onClick={(e) => {
                            setBenefitDetailsDialog({
                              title: benefit.nome,
                              description: benefit.descricao,
                              link: benefit.link,
                            });
                            setBenefitDetailsDialogOpen(true);
                          }}
                        />
                      </div>
                    </td>
                    {isTeuCardOdonto ? (
                      <td>{currencyMask(benefitDetails?.valorParcelTotal)}</td>
                    ) : (
                      <td>{currencyMask(benefit.valorMensalidade)}</td>
                    )}

                    <td>
                      {isTeuCardOdonto && (
                        <button
                          style={{
                            background: 'none',
                            border: 'none',
                            cursor: 'pointer',
                          }}
                          type="button"
                          onClick={() => {
                            setDialogIsOpenDetails(true);
                          }}
                        >
                          <img alt="" width={18} src={icons.searchIcon} />
                        </button>
                      )}
                    </td>
                    <td>{moment(benefit.dataAdesao).format('DD/MM/YYYY')}</td>
                    <td>
                      {isTeuCardOdonto && (
                        <>
                          {activeCardState === 'ATIVO' ? (
                            <St.BtnPlus
                              onClick={() => {
                                setBenefitSelected(benefit);
                                setDialogIsOpenDependent(true);
                              }}
                            />
                          ) : (
                            <St.BtnPlusGray
                              onClick={() => {
                                setBenefitSelected(benefit);
                                setDialogIsOpenDependent(true);
                              }}
                              disabled
                            />
                          )}
                        </>
                      )}
                    </td>
                    <td>
                      {benefit.assinada &&
                      benefit.statusBeneficio === 'ATIVO' ? (
                        <img
                          width="32"
                          src={icons.emojiAtivo}
                          alt="checkGreen"
                        />
                      ) : benefit.statusBeneficio === 'PENDENTE' ? (
                        <img
                          width="32"
                          src={icons.emojiPendente}
                          alt="checkYellow"
                        />
                      ) : (
                        <img
                          width="32"
                          src={icons.emojiSuspenso}
                          alt="checkYellow"
                        />
                      )}
                    </td>
                    <td>
                      {!benefit?.extraido ? (
                        <div style={{ cursor: 'pointer' }}>
                          <img
                            width="18"
                            src={icons.trashCanRed}
                            alt="trash"
                            onClick={() => {
                              setBenefitSelected(benefit);
                              setDialogJustificationIsDelete(true);
                            }}
                          />
                        </div>
                      ) : (
                        <>
                          {!isTeuCardOdonto ? (
                            <div style={{ cursor: 'pointer' }}>
                              <St.BtnCancel
                                onClick={() => {
                                  if (isTeuCardCuida) {
                                    setBenefitSelected(benefit);
                                    setDialogIsOpenCancelCuida(true);
                                  } else {
                                    setBenefitSelected(benefit);
                                    setDialogIsOpenCancel(true);
                                  }
                                }}
                              />
                            </div>
                          ) : (
                            <div style={{ cursor: 'pointer' }}>
                              <img
                                width="18"
                                src={icons.trashCanRed}
                                alt="trash"
                                onClick={() => {
                                  if (isTeuCardCuida) {
                                    setBenefitSelected(benefit);
                                    setDialogIsOpenCancelCuida(true);
                                  } else {
                                    setBenefitSelected(benefit);
                                    setDialogJustificationIsDelete(true);
                                  }
                                }}
                              />
                            </div>
                          )}
                        </>
                      )}
                    </td>
                  </tr>
                );
              })}
          </St.Table>
        </ContainerFlex>

        <ContainerFlex marginTop={24}>
          <St.TableTitle>Benefícios Cancelados</St.TableTitle>
          <St.Table>
            <tr>
              <th>Nome</th>
              <th>Descrição</th>
              <th>Parcela (R$)</th>
              <th>Data Adesão</th>
              <th>Data Cancelamento</th>
              <th>Status</th>
            </tr>
            {contractedBenefits?.beneficios
              ?.filter((el) => el.statusBeneficio === 'CANCELADO')
              ?.map((benefit, idx) => (
                <tr key={benefit.nome + idx.toString()}>
                  <td>{benefit.nome}</td>
                  <td>
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        gap: '5px',
                      }}
                    >
                      <span
                        style={{
                          maxWidth: '46ch',
                          height: '1.5rem',
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                        }}
                      >
                        {benefit.descricao.replace(',', '')}
                      </span>

                      <img
                        width={20}
                        height={20}
                        src={icons.exclamation}
                        alt="informações"
                        onClick={(e) => {
                          setBenefitDetailsDialog({
                            title: benefit.nome,
                            description: benefit.descricao,
                            link: benefit.link,
                          });
                          setBenefitDetailsDialogOpen(true);
                        }}
                      />
                    </div>
                  </td>
                  <td>{currencyMask(benefit.valorMensalidade)}</td>
                  <td>{moment(benefit.dataAdesao).format('DD/MM/YYYY')}</td>
                  <td>
                    {moment(benefit.dataCancelamento).format('DD/MM/YYYY')}
                  </td>
                  <td className="icon-table">
                    <img
                      width="35"
                      src={icons.emojiCancelado}
                      alt="handshake"
                    />
                  </td>
                </tr>
              ))}
          </St.Table>
          {dialogIsOpenDetails && (
            <Dialog
              open={dialogIsOpenDetails}
              title={
                <span style={{ color: 'red', fontSize: '2rem' }}>
                  Detalhes do benefício
                </span>
              }
              positiveLabel="Voltar"
              positiveAction={() => setDialogIsOpenDetails(false)}
              icon={icons.IconTick}
            >
              <St.TableHistory>
                <tr>
                  <th>Adesão</th>
                  <th>Nome</th>
                  <th>Parcela (R$)</th>
                  <th>Data de adesão</th>
                  <th>Assinado</th>
                  <th>Status</th>
                  <th style={{ borderRight: '1px solid grey' }}>Excluir</th>
                </tr>
                {benefitDetails?.detalhes?.map((benefit) => (
                  // O status foi feito desta forma pois hoje temos um unico beneficio
                  <tr>
                    <td>{benefit.adesao}</td>
                    <td>{benefit.nome}</td>
                    <td>{`R$ ${benefit.parcela}`}</td>

                    <td>{moment(benefit.dataAdesao).format('DD/MM/YYYY')}</td>
                    <td>{benefit.assinado}</td>
                    <td>{benefit.status}</td>
                    <td style={{ borderRight: '1px solid grey' }}>
                      {benefit.adesao !== 'Titular' && (
                        <img
                          style={{ cursor: 'pointer' }}
                          width="18"
                          src={icons.trashCanRed}
                          alt="trash"
                          onClick={() => handleDeleteDependent(benefit)}
                        />
                      )}
                    </td>
                  </tr>
                ))}
              </St.TableHistory>
              <Dialog
                icon={
                  RequestDeleteBenefitDependent.status >= 200 &&
                  RequestDeleteBenefitDependent.status <= 300
                    ? icons.confirmation
                    : icons.warning
                }
                open={dialogDeleteDependent}
                positiveLabel="Entendi"
                positiveAction={() => {
                  getUserData();
                  setDialogDeleteDependent(false);
                }}
                message={deleteDependentMessage}
              />
            </Dialog>
          )}
        </ContainerFlex>
      </St.BoxCard>
    </Container>
  );
};

export default SalesDetail;
